$theme-colors: (
	"basic-unicorn": #696bbd,
	"downgrade": #fac70f,
	"instant": #ea202b,
	"magic": #80ca3a,
	"magical-unicorn": #56c0f0,
	"upgrade": #f46d16,
);

@import "~bootstrap/scss/bootstrap.scss";

body,
html,
#root {
	width: 100%;
	height: 100%;
}

@font-face {
	font-family: "OstrichSans-Heavy";
	src: url("fonts/OstrichSans-Heavy.ttf.woff") format("woff"),
		url("fonts/OstrichSans-Heavy.ttf.eot") format("embedded-opentype");
	font-weight: normal;
	font-style: normal;
}

.ostrich {
	font-family: "OstrichSans-Heavy", sans-serif;
}

.title {
	font-size: 64pt;
}

.overflow-scroll {
	overflow: scroll;
}

.btn.nav-link {
  border: 0;
}
